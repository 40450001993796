import axios from "axios"
import cookie from 'vue-cookies'
import router from '@/router'

export const auth = {
	state: () => ({
		user: {},
		token: '',
		header: {},
		role: '',
		users: [],
		
		
	}),
	getters: {
		users(state) {
			return state.users
		},
		
		
		user(state) {
			return state.user
		},
		token(state) {
			return state.token
		},
		header(state) {
			return state.header
		},
		role(state) {
			return state.role
		}
	},
	mutations: {
		users(state, payload) {
			state.users = payload
		},
		adduser(state, payload) {
			state.users.push(payload)
		},
		role(state, payload) {
			state.role = payload
		},
		user(state, payload) {
			state.user = payload
		},
		setToken(state, payload) {
			state.token = payload
		},
		header(state, payload) {
			state.header = payload
		}
	},
	actions: {
		getUsers({dispatch,commit}) {
			dispatch('request',{
				url: 'auth/allusers'
			}).then(res => {
				if (res.status === 200) {
					commit('users', res.data)
				}
			})
		},
		
		registration({dispatch,commit}, payload) {
			dispatch('request',{
				url: 'auth/registration',
				method:'post',
				payload
			})
				.then(res => {
					if (res.status === 201) {
						commit('adduser', res.data)
					}
				}).catch(err => {
					console.log(err)
				})
		},
		addadmin({dispatch}) {
			dispatch('request',{
				url: 'auth/login/addadmin'
			}).then(res => {
					console.log(res.data)
				}).catch(err => {
					console.log(err)
				})
		},

		login(context, payload) {
			axios.post(`${context.getters.url}/auth/login`, payload)
				.then(res => {
					if (res.status == 200) {
						context.commit('user', res.data.user)
						context.commit('setToken', res.data.token)
						// context.dispatch('setLayout', res.data.user.role + '-layout')
						context.commit('role', res.data.user.role)
						context.commit('header', {
							"authorization": `Bearer ${res.data.token}`
						})

						router.push('/dashboard/home')
						
						// delete user.role
						cookie.set('arm-token', res.data.token)
					}
				}).catch(err => {
					let {
						response
					} = err
					context.dispatch('notification', {
						type: 'error',
						message: response.data.message
					})
				})
		},
		checkUser(context) {
			if (!cookie.get('arm-token')){
				// context.dispatch('setLayout', 'auth-layout')
				router.push('/')
			} else {

			}
			context.commit('setToken', cookie.get('arm-token'))
			context.commit('header', {
				"authorization": `Bearer ${cookie.get('arm-token')}`
			})
			axios.get(`${context.getters.url}/auth/checkuser`,{
					headers: context.getters.header
				}).then(res => {
				if (res.status == 200) {
					context.commit('role', res.data.role)
					context.commit('user',res.data)
					// context.commit('layout', res.data.role + '-layout')
				} else {
					// context.commit('layout', 'auth')
				}
			}).catch(err => {
				console.log(err)
			})
		},
		exit(context) {
			cookie.remove('arm-token')
			// context.commit('layout', 'user-layout')
			context.commit('user', {})
			context.commit('setToken', '')
			context.commit('role', '')
		},
		checkLogin(context, payload) {
			return axios.post(`${context.getters.url}/auth/checklogin`, payload, {
				headers: context.getters.header
			})
		}

	}
}