<template>
  <router-view/>
</template>

<script>

export default {
  methods:{
    checkUser(){
      if (this.$cookies.isKey('arm-token')){
        this.$store.dispatch('checkUser',this.$cookies.get('arm-token'))
      } else {
        // this.$router.push('/')
        // this.$store.dispatch('notification',{
        //   type:'error',
        //   message:'Сеанс окончено! Авторизуйтесь еще раз!'
        // })

          // this.$store.dispatch('setLayout','user-layout')
        
      }
      // if(this.$cookies.isKey('theme-fon')){
      //   this.$store.dispatch('apiToggleTheme', this.$cookies.get('theme-fon').toggle)
      // }
    }
  },
  watch:{
    $route(to){
      if (to.name ==='home'){
        this.$store.commit('headerSearch',false)
        this.$store.commit('headerLogo',false)
      }
    }
  },
  mounted(){
    document.title = 'UMFT eLib'
    this.checkUser()
  }
}
</script>

<style lang="scss">
@import './styles/reset.scss';
@import './styles/grid.scss';
@import './styles/main.scss';
@import './styles/component/book.scss';
</style>
