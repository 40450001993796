import axios from "axios";
import { ElNotification } from "element-plus";
import {ElMessage} from 'element-plus'
export const giveBook = {
  state: () => ({
    result: [],
    listBook: [],
    allGiveBook: [],
    countGiveBook: 0,
    giveBookStat:{}
  }),
  getters: {
    giveBookStat({giveBookStat}){
      return giveBookStat
    },
    result(state) {
      return state.result;
    },
    getBook(state) {
      return state.listBook;
    },
    allGiveBook(state) {
      return state.allGiveBook.map((book) => {
        book.data = book.data
          ? new Date(book.data).toLocaleString()
          : "Hali topshirilmagan";
        book.createdAt = new Date(book.createdAt).toLocaleString();
        return book;
      });
    },
    countGiveBook(state) {
      return state.countGiveBook;
    },
  },
  mutations: {
    setGiveBookStat(state,payload){
      state.giveBookStat = payload
    },
    result(state, payload) {
      state.result = payload;
    },
    listBook(state, payload) {
      state.listBook = payload;
    },
    getGiveBook(state, payload) {
      state.allGiveBook = payload;
    },
    addGiveBook(state, payload) {
      state.allGiveBook = [payload, ...state.allGiveBook];
      state.countGiveBook += 1
    },
    countGiveBook(state, payload) {
      state.countGiveBook = payload;
    },
    updateGiveBook(state,payload){
      state.allGiveBook = state.allGiveBook.map(book => {
        if (book._id == payload._id) return payload
        return book
      })
    }
  },
  actions: {
    goList({dispatch,commit}, payload) {
      dispatch('request',{
        url: 'givebook/select',
        params: {
          value: payload
        },
      }).then((res) => {
          if (res.status == 200) {
            commit("result", res.data);
          }
        });
    },
    listBook({ getters, commit,dispatch }) {
      dispatch('request',{
        url: 'paperbook/forgivebook'
      })
        .then((res) => {
          commit("listBook", res.data);
        });
    },
    addGiveBook({dispatch,commit}, payload) {
      dispatch('request',{
        url: 'givebook/create',
        method:'post',
        data:payload
      })
        .then((res) => {
          commit("notification", {
            type: "success",
            message: "Muvafaqqiyatli",
          });
          commit("addGiveBook", res.data);
        }).catch((err) => {
          ElMessage({
            type: 'warning',
            message: err.response?.data?.msg
          })
          
        })
    },
    getGiveBook({dispatch,commit}, params) {
      commit("loading", true);
      dispatch('request',{
        url: 'givebook',
        params
      })
        .then((res) => {
          // console.log(res.data)
          commit("getGiveBook", res.data.givebooks);
          commit("countGiveBook", res.data.count);
          commit('setGiveBookStat', {
            count: res.data.count,
            count_give: res.data.count_give,
            count_base: res.data.count_base            
          })
          commit("loading", false);
        });
    },
    changeGiveBook({commit,dispatch},payload){
      if (!payload) return false
      dispatch('request',{
        url: `givebook/change/${payload}`
      })
      .then(res => {
        commit('updateGiveBook',res.data)
        ElMessage({
          type: 'success',
          message: 'Kitob holati o`zgartirildi'
        })
      })
    }
  },
};
