import axios from "axios"
import { ElNotification } from 'element-plus'
import {ElMessage} from "element-plus"
export const languageBook = {
  state:() => ({
    allLanguage:[],
    count:0,
    editLanguage:{},
    changeCategory:[]
  }),
  getters:{
    count(state){
      return state.count
    },
    getAllLanguage(state){
      return state.allLanguage
    },
    editLanguage(state){
      return state.editLanguage
    },
    changeCategory(state){
      return state.changeCategory
    }
  },
  mutations:{
    allLanguage(state,payload){
      state.allLanguage = payload
    },
    count(state,payload){
      state.count = payload
    },
    deleteLanguage(state,payload){
      let index = state.allLanguage.findIndex(lang => lang._id == payload)
      if (index !==-1){
          state.allLanguage.splice(index,1)
      }
    },
    editLanguage(state,payload){
      state.editLanguage = payload
    },
    upLanguage(state,payload){
      let index = state.allLanguage.findIndex(cat => cat._id == payload._id)
      if (index !==-1){
          state.allLanguage[index] = payload
      }
    },
    changeCategory(state,payload){
      state.changeCategory = payload
    }
  },
  actions:{
    allLanguage({commit,dispatch},params){
      commit('loading',true)
      dispatch('request',{
        url: 'language',
        params
      })
      .then(res=> {
          if (res.status==200){
              commit('allLanguage',res.data.language)
              commit('count',res.data.count)
              commit('loading',false)
          }
      })
    },
    addLanguage({commit,dispatch}, payload) {
      dispatch('request', {
        url: 'language/create',
        method: 'post',
        data: payload
      }).then(res => {
        if (res.status == 200) {
          ElMessage.success('Muvafaqqiyatli qo`shildi')
          commit('addCategory', res.data)
        }
      })
    },
    editLanguage({dispatch},payload){
      return dispatch('request',{
        url: `language/${payload}`
      })
    },
    saveLanguage({dispatch,commit},payload){
      dispatch('request',{
        url: `language/${payload._id}`,
        method:'put',
        data:payload
      })
      .then(res=> {
        if (res.status==201){
          commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          commit('modal',false)
          commit('upLanguage',payload)
        }
      })
    },
    deleteLanguage({dispatch,commit},payload){
      dispatch('request',{
        url: `language/${payload}`,
        method:'delete'
      })
      .then(res => {
          if (res.status == 200){
              commit('notification',{
                  type:'success',
                  message:'Muvafaqqiyatli'
              })
              commit('deleteLanguage',payload)
          }
      })
    },
    changeStatusLanguage({dispatch,commit},payload){
      dispatch('request',{
        url: `language/change/${payload._id}/${payload.status}`
      })
      .then(res=>{
        if (res.status == 200) {
            commit('upLanguage',res.data)
            ElNotification({
                title: 'Til holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
  }
}