import axios from "axios"

export  const profile ={
  state: () => ({
    allRequest:[],
    downloadBook:[],
    findUser:{
			user:{
				login:''
			}
		},
		findAdmin:{}
  }),
  getters:{
    downloadBook(state) {
			return state.downloadBook
		},
    allRequest(state){
      return state.allRequest
    },
    findUsers(state) {
			return state.findUser
		},
		userAdmin(state) {
			return state.findAdmin
		},
  },
  mutations:{
    downloadBook(state, payload) {
			state.downloadBook = payload
		},
    addRequest(state, payload) {
			state.allRequest.push(payload)
		},
    requestbook(state, payload) {
			state.allRequest = payload
		},
    findUser(state, payload) {
			state.findUser = payload
		},
		
		findAdmin(state, payload) {
			state.findAdmin = payload
		},
    saveProfile(state, payload) {
			state.findUser = payload
		},
		saveProfileAdmin(state, payload) {
			state.findAdmin = payload
		},
  },
  actions:{
    getDownloadBook({dispatch,commit},payload){
			dispatch('request',{
        url: 'downloadbook',
        payload
      }).then(res=> {
			if (res.status==200){
				let books = res.data.downloadbook
					books = books.map(book => {
					
						if (book.book?.author) {
							book.book.author = JSON.parse(book.book?.author)
						}
						let year = new Date(book.book?.year)
						if (book.book?.year) {
							book.book.year = year.getFullYear()
						}
						book.createdAt = new Date(book.createdAt).toLocaleString()
						if (book.book?.language == 0) {
							book.book.language = "O'zbek tili"
						} else if (book.book?.language == 1) {
							book.book.language = "Rus tili"
						} else {
							book.book.language = "Ingliz tili"
						}
						return book
					})
				commit('downloadBook',res.data.downloadbook)
			}
			})
		},
    downloadFile({dispatch},payload){
			dispatch('request',{
        url: 'downloadbook',
        method:'post',
          book: payload
        
      })
		.then(res=> {
			if (res.status==201){
				// context.commit('paperBook',res.data.paperbooks)
			}
			})
		},
    getRequest({dispatch,commit},payload){
      commit('loading',true)
      dispatch('request',{
        url: 'requestbook',

      }).then(res=> {
        if (res.status==200){
          commit('requestbook',res.data)
          commit('loading',false)
        }
      })
    },
    addRequest({dispatch,commit},payload){
      dispatch('request',{
        url: 'requestbook',
        method:'post',
        payload
      }).then(res => {
        if (res.status == 201) {
          commit('addRequest', res.data)
        }
      })
    },
		findAdmin({dispatch}){
      return dispatch('request',{
        url: 'users/profile'
      })
    },
    findUser({dispatch}){
      return dispatch('request',{
        url: 'auth/finduser'
      })
    },
    async saveProfile({commit,dispatch},payload){
      let res = await dispatch('request',{
        url: `auth/update/${payload._id}`,
        method:'put',
        payload
      })
      if (res.status==201){
        // commit('saveProfile',res.data)
        commit('user', res.data)
        dispatch('findUser')
      }

    },
		saveAdminProfile({commit,dispatch},payload){
      dispatch('request',{
        url: `users/${payload._id}`,
        method:'put',
        payload
      })
      .then(res=> {
          if (res.status==201){
            commit('saveProfileAdmin',res.data)
            commit('user', res.data)
            dispatch('findAdmin')
          }
      })
    },
  }
}