import axios from "axios"
import { ElNotification } from 'element-plus'

export const settings = {
  state: () => ({
    allStudent:[],
    countStudent:0,
    countSection:0,
    countWorker:0,
    allRequestSetting:[],
    allWorker:[],
    allRequestCount:[]
  }),
  getters:{
    allStudent(state){
      return state.allStudent
    },
    allWorker(state){
      return state.allWorker
    },
    allRequestSetting(state){
      return state.allRequestSetting
    },
    allRequestCount(state) {
      return state.allRequestCount
    },
    countSection(state){
      return state.countSection
    },
    countWorker(state){
      return state.countWorker
    },
    countGroups(state){
      return state.countGroups
    },
    countStudent(state){
      return state.countStudent
    },
  },
  mutations:{
    allStudent(state,payload){
      state.allStudent = payload
    },
    allWorker(state,payload){
      state.allWorker = payload
    },
    allRequest(state,payload){
      state.allRequestSetting = payload
    },
    allRequestCount(state, payload) {
      state.allRequestCount = payload;
    },
    addstudents(state,payload){
      state.allStudent = payload
    },
    add_students(state,payload){
      state.allStudent = [...payload,...state.allStudent]
    },
    addsection(state,payload){
      state.allDepartment = payload
    },
    addWorker(state,payload){
      state.allWorker.push(payload)
    },
    countStudent(state,payload){
      state.countStudent = payload
    },
    countSection(state,payload){
      state.countSection = payload
    },
    countWorker(state,payload){
      state.countWorker = payload
    },
    deleteStudent(state,payload){
      let index = state.allStudent.findIndex( student=> student._id == payload)
      if (index !==-1){
          state.allStudent.splice(index,1)
      }
    },
    deleteSection(state,payload){
      let index = state.allDepartment.findIndex( student=> student._id == payload)
      if (index !==-1){
          state.allDepartment.splice(index,1)
      }
    },
    deleteWorker(state,payload){
      let index = state.allWorker.findIndex( student=> student._id == payload)
      if (index !==-1){
          state.allWorker.splice(index,1)
      }
    },
    upStudent(state,payload){
      let index = state.allStudent.findIndex(cat => cat._id == payload._id)
      if (index !==-1){
          state.allStudent[index] = payload
      }
    },
    upWorker(state,payload){
      let index = state.allWorker.findIndex(cat => cat._id == payload._id)
      if (index !==-1){
          state.allWorker[index] = payload
      }
    },
    
  },
  actions:{
    getAllRequest({commit,dispatch},params){
      commit('loading',true)
      dispatch('request',{
          url: 'requestbook/all',
          params,
      })
      .then(res=> {
          if (res.status==200){
              commit('allRequest',res.data.requestbook)
              commit('allRequestCount', res.data.count)
              commit('loading',false)
          }
      })
    },
    editRequest({dispatch},payload){
      return dispatch('request',{
        url: `requestbook/${payload}`
      })
    },
    addAnswerRequest({dispatch,commit},payload){
      dispatch('request',{
        url: `requestbook/${payload._id}`,
        method:'put',
        payload
      })
      .then(res=> {
        if (res.status==201){
          commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          // context.commit('upGroup',payload)
        }
      })
    },
    changeStatusRequest({dispatch,commit,},payload){
      dispatch('request',{
        url: `requestbook/change/${payload._id}/${payload.status}`,
        method:'put'
      })
      .then(res=>{
        if (res.status == 200) {
            ElNotification({
                title: 'So`rov holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
    getStudent({dispatch,commit},payload){
      commit('loading',true)
      dispatch('request',{
        url: 'student',
        params: {
          ...payload
        },
      })
      .then(res=> {
        commit('allStudent',res.data.students)
        commit('countStudent',res.data.count)
        commit('loading',false)
      })
    },
    getWorker({dispatch,commit},payload){
      commit('loading',true)
      dispatch('request',{
        url: 'worker',
        params: {
          section: payload
        }
      })
      .then(res=> {
          if (res.status==200){
              commit('allWorker',res.data.worker)
              commit('countWorker',res.data.count)
              commit('loading',false)
          }
      })
    },
    addStudent({dispatch,commit},payload){
      dispatch('request',{
        url: 'student',
        method:'post',
        payload
      })
      .then(res=> {
          if (res.status==201){
              commit('addstudents',res.data)
          }
      })
    },
    addWorker({dispatch,commit},payload){
      dispatch('request',{
        url: 'worker',
        method:'post',
        payload
      }).then(res=> {
          if (res.status==201){
            commit('notification',{
              type:'success',
              message:'Muvafaqqiyatli'
          })
            commit('addWorker',res.data)
          }
      })
    },
    deleteStudents({dispatch,commit},payload){
      dispatch('request',{
        url: `student/${payload}`,
        method:'delete'
      })
      .then(res => {
          if (res.status == 200){
              commit('notification',{
                  type:'success',
                  message:'Muvafaqqiyatli'
              })
              commit('deleteStudent',payload)
          }
      })
    },
    deleteWorker({dispatch,commit},payload){
      dispatch('request',{
        url: `worker/${payload}`,
        method:'delete'
      })
      .then(res => {
          if (res.status == 200){
              commit('notification',{
                  type:'success',
                  message:'Muvafaqqiyatli'
              })
              commit('deleteWorker',payload)
          }
      })
    },
    editStudent({dispatch},payload){
      return dispatch('request',{
        url: `student/${payload}`
      })
    },
    editWorker({dispatch},payload){
      return dispatch('request',{
        url: `worker/${payload}`
      })
    },
    saveStudent({dispatch,commit},payload){
      dispatch('request',{
        url: `student/${payload._id}`,
        method:'put',
        payload
      }).then(res=> {
        if (res.status==201){
          commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          commit('upStudent',payload)
        }
      })
    },
    saveWorker({dispatch,commit},payload){
      dispatch('request',{
        url: `worker/${payload._id}`,
        method:'put',
        payload
      })
      .then(res=> {
        if (res.status==201){
          commit('notification',{
              type:'success',
              message:'Muvaffaqiyatli'
          })
          // context.commit('upStudent',payload)
        }
      })
    },
    changeStatusStudent({dispatch,commit},payload){
      dispatch('request',{
        url: `student/change/${payload._id}/${payload.status}`
      })
      .then(res=>{
        if (res.status == 200) {
            commit('upStudent',res.data)
            ElNotification({
                title: 'Yo`nalish holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
    changeStatusSection({dispatch,commit},payload){
      dispatch('request',{
        url: `section/change/${payload._id}/${payload.status}`
      })
      .then(res=>{
        if (res.status == 200) {
            commit('upDepartment',res.data)
            ElNotification({
                title: 'Yo`nalish holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
    changeStatusWorker({dispatch,commit},payload){
      dispatch('request',{
        url: `worker/change/${payload._id}/${payload.status}`
      })
      .then(res=>{
        if (res.status == 200) {
            commit('upWorker',res.data)
            ElNotification({
                title: 'Yo`nalish holati o`zgartirildi',
                type: 'success',
            })
        }
      })
    },
  }
}