import axios from "axios"
import { ElMessage } from "element-plus"

export const paperBook = {
  state:() => ({
    paperbooks:[],
    findAuthor: '',
    languageBook:[],
    allPaperBook: [],
    countPaperBook:0,
    countGive:0,
    editBook:'',
    findInvent:0,
    paperview:{}
  }),
  getters:{
    countGive(state){
      return state.countGive
    },
    paperbooks(state){
      return state.paperbooks
    },
    paperview(state){
      return state.paperview
    },
    getFindAuthor(state){
      return state.findAuthor
    },
    allPaperBook(state){
      return state.allPaperBook
    },
    countPaperBook(state){
      return state.countPaperBook
    },
    editBook(state){
      return state.editBook
    },
    findInvent(state){
      return state.findInvent
    },
  },
  mutations:{
    setCountGiveBook(state,payload){
      state.countGive = payload
    },
    paperBook(state, payload) {
      state.allPaperBook = payload
    },
    paperview(state,payload){
      state.paperview = payload
    },
    searchUdk(state,payload){
      state.paperbooks = payload
    },
    findAuthor(state,payload){
      state.findAuthor = payload
    },
    findInvent(state,payload){
      state.findInvent = payload
    },
    countPaperBook(state,payload){
      state.countPaperBook = payload
    },
    editBook(state,payload){
      state.editBook = payload
    },
    // addPaperBook(state,payload){
    //   state.allPaperBook = [...payload,...state.allPaperBook]
    // },
    addPaperBook(state, payload) {
      console.log("payload",payload);
      state.allPaperBook = [payload,...state.allPaperBook]
    },
    paperBookDelete(state,payload){
      let index = state.allPaperBook.findIndex(paper => paper._id == payload)
      if (index !==-1){
          state.allPaperBook.splice(index,1)
          state.countPaperBook-=1
      }
    },
    upPaperBook(state,payload){
      let index = state.allPaperBook.findIndex(cat => cat._id == payload._id)
      if (index !==-1){
          state.allPaperBook[index] = payload
      }
    },
  },
  actions:{
    allPaperBook({dispatch,commit},payload){
      commit('paperBook', [])
      commit('loading',true)
      dispatch('request',{
        url: 'paperbook',
        params: {
          ...payload
        },
      })
      .then(res=> {
        commit('paperBook',res.data.paperbooks)
        commit('countPaperBook',res.data.count)
        commit('setCountGiveBook',res.data.countGive)

        commit('loading',false)
        
      })
    },
    addPaperBook({commit,dispatch}, data) {
      dispatch('request', {
        url: 'paperbook/create',
        method: 'post',
        data
      }).then(() => {
        ElMessage.success('Muvafaqqiyatli qo`shildi')
        
      })
    },
    deletePaperBook({dispatch,commit},payload){
      dispatch('request',{
        url: `paperbook/${payload}`,
        method:'delete'
      })
      .then(res => {
          if (res.status == 200){
            ElMessage.success("Muvafaqqiyatli o`chirildi")
              commit('paperBookDelete',payload)
          }
      })
    },
    editPaperBook({dispatch},payload){
        return dispatch('request',{
          url: `paperbook/${payload}`
        })
        
    },
    savePaperBook({dispatch,commit},payload){
      dispatch('request',{
        url: `paperbook/${payload._id}`,
        method:'put',
        data:payload
      })
      .then(res=> {
        if (res.status==200){
          ElMessage.success('Muvaffaqiyatli o`zgartirildi')
          commit('upPaperBook',payload)
        }
      })
    },
    searchUdkCode(context,payload){
      axios.get(`https://udc-api.perviy-vestnik.ru/api/search?request=${payload}`)
      .then(res => {
          if (res.data !=='error'){
              res.data = res.data.map(book => {
                  return book 
              })
              context.commit('searchUdk',res.data)
          } else {
              context.commit('searchUdk',[])
          }
      })
    },
    findAuthor({dispatch},payload){
      return dispatch('request',{
        url: 'paperbook/searchauthor',
        params: payload,
      })
    },
    async getGroupExcel({dispatch},payload){
      return await dispatch('request',{
        url: 'paperbook/excel',
        params: payload
      })
    },
    async getDownload({dispatch},payload){
      console.log('pay',payload);
      return await dispatch('request',{
        url: `paperbook/barcode/${payload}`
      })
    },
    findInvent({dispatch,commit},payload){
      console.log("pay",payload);
      dispatch('request',{
        url: 'paperbook/lastinvent',
        params: payload
      })
    .then(res =>{
      if(res.status == 200){
        commit('findInvent',res.data)
      }
    })
    },
  }
}