import { createStore } from 'vuex'
import cookie from 'vue-cookies'

// import { layoutApp } from './modules/layout'
import { auth } from './modules/auth'
import { notification } from './modules/notif'
import { modal } from './modules/modal'
import { category } from './data/category'
import { subcategory } from './data/subcategory'
import { book } from './data/book'
import { languageBook } from './data/languageBook'
import { paperBook } from './data/paperBook'
import { favorites } from './data/favorites'
import { settings } from './data/settings'
import { profile } from './data/profile'
import { giveBook } from './data/giveBook'
import { dashboard } from './data/dashboard'
import { getBook } from './data/getBook'
import { student } from './data/student'
import { filial } from './data/settings/filial'
import {apiStore} from './helpers/axios'

export default createStore({
  state: {
    url: 'https://armapi.umft.uz',
    // url: 'http://192.168.20.48:3007',
    months:['Yanvar','Fevral','Mart','Aprel','May','Iyun','Iyul','Avgust','Sentabr','Oktabr','Noyabr','Dekabr'],
    loading:false,
    toggleTheme: cookie.get("theme-fon")?.toggle,
    headerSearch:false,
    headerLogo:false
  },
  getters: {
    url(state){
      return state.url
    },
    months(state){
      return state.months
    },
    loading(state){
      return state.loading
    },
    toggleTheme(state){
      return state.toggleTheme
    },
    headerSearch(state){
      return state.headerSearch
    },
    headerLogo(state){
      return state.headerLogo
    }
  },
  mutations: {
    loading(state,payload){
      state.loading = payload
    },
    toggleTheme(state,payload){
      state.toggleTheme = payload
    },
    headerSearch(state,payload){
      state.headerSearch = payload
    },
    headerLogo(state,payload){
      state.headerLogo = payload
    }
  },
  actions: {
    apiToggleTheme(context,payload){
      cookie.remove('theme-fon')
      cookie.set('theme-fon',{
        toggle:payload,
      })
      context.commit('toggleTheme',payload)
    }
  },
  modules: {
    auth, 
    notification, modal, 
    category, book, subcategory, 
    languageBook, paperBook,
    favorites,settings,profile,
    giveBook,dashboard,
    getBook, student, filial,
    apiStore
  }
})
