import axios from "axios"
import {ElMessage} from "element-plus"
export const category = {
    state: () => ({
        category: [],
        onecategory: {},
        categoryCount: [],
        countcategory: [],

    }),
    getters: {
        category(state) {
            return state.category
        },
        categoryCount(state) {
            return state.categoryCount
        },
        countcategory(state) {
            return state.countcategory
        },
        activeCategory(state) {
            return state.category.filter(cat => cat.status === 0)
        },
        onecategory(state) {
            return state.onecategory
        }
    },
    mutations: {
        category(state, payload) {
            state.category = payload
        },
        countcategory(state, payload) {
            state.countcategory = payload
            state.countcategory = state.countcategory.map(item=>{
                if (item.count < 0){
                    return false
                }
                return item
            })
        },
        categoryCount(state, payload) {
            state.categoryCount = payload;
        },
        onecategory(state, payload) {
            state.onecategory = payload
        },
        addCategory(state, payload) {
            state.category.push(payload)
        },
        deleteCategory(state, payload) {
            let index = state.category.findIndex(cat => cat._id == payload)
            if (index !== -1) {
                state.category.splice(index, 1)
            }
        },
        upCategory(state, payload) {
            let index = state.category.findIndex(cat => cat._id == payload._id)
            if (index !== -1) {
                state.category[index] = payload
                state.category[index].status = payload.status ? 0 : 1
            }
        }
    },
    actions: {
        getCategory({dispatch}, payload) {
            return dispatch('request',{
                url: `category/${payload}`
            })
        },
        viewCategory({commit,dispatch}, payload) {
            dispatch('request', {
                url: `category/edit/${payload}`
            }).then(res => {
                if (res.status === 200) {
                    commit('onecategory', res.data)
                }
            })
        },
        deleteCategory({commit,dispatch}, payload) {
            dispatch('request',{
                url: `category/delete/${payload}`,
                method:'delete'
            }).then(res => {
                    if (res.status == 200) {
                        ElMessage.success( 'Muvafaqqiyatli o`chirildi')
                        commit('deleteCategory', payload)
                    }
                })
        },

        allCategory({commit,dispatch}, params) {
            dispatch('request', {
                url: 'category',
                params
            }).then(res => {
                commit('category', res.data.category)
                commit("categoryCount", res.data.count);

            })
        },
        allCategoryCount({commit,dispatch}) {
            dispatch('request',{
                url: 'category/count'
            }).then(res => {
                commit('countcategory', res.data)
                    
            })
        },
        addCategory({commit,dispatch}, data) {
            dispatch('request',{
                url: 'category/create',
                method:'post',
                data
            }).then(res => {
                if (res.status == 200) {
                    ElMessage.success('Muvafaqqiyatli qo`shildi')
                    commit('addCategory', res.data)
                }
            })
        },
        saveCategory({commit,dispatch}, payload) {
            dispatch('request',{
                url: `category/save/${payload._id}`,
                method:'put',
                data:payload
            }).then(res => {
                if (res.status == 200) {
                    ElMessage.success('Muvafaqqiyatli tahrirlandi')
                    commit('modal', false)
                    commit('upCategory', payload)
                }
            })
        }
    }

}