import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    component: () => import('@/layout/userLayout.vue'),
    children:[
      {
        path: '/',
        name: 'home',
        component: HomeView,
      },
      
      {
        path: '/category/:id',
        name: 'categorybook',
        component: () =>
          import( /* webpackChunkName: "products" */ '../views/CategoryView.vue'),
      },

      {
        path: '/allBook',
        name: 'allBook',
        component: () =>
          import( /* webpackChunkName: "allBook" */ '../views/AllBook.vue'),
      }, {
        path: '/search',
        name: 'search',
        component: () =>
          import( /* webpackChunkName: "search" */ '../components/Search.vue'),
      },
      {
        path: '/students',
        name: 'students',
        component: () =>
          import( /* webpackChunkName: "students" */ '../views/admin/HomeAdmin.vue'),
      }, {
        path: '/profile',
        name: 'profile',
        component: () =>
          import(
            /* webpackChunkName: "sign-in" */
            '../components/profile/Profile.vue'
          ),
        children: [{
            path: 'set',
            name: 'set',
            component: () =>
              import(
                /* webpackChunkName: "set" */
                '../components/profile/ProfileSetting.vue'
              ),
          },
          {
            path: 'download',
            name: 'download',
            component: () =>
              import(
                /* webpackChunkName: "download" */
                '../components/profile/ProfileDownload.vue'
              ),
          },
          {
            path: 'request',
            name: 'request',
            component: () =>
              import(
                /* webpackChunkName: "request" */
                '../components/profile/ProfileRequest.vue'
              ),
          },
        ],
      },
    ]
  },
  {
    path: '/auth',
      component: () => import('@/layout/authLayout.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import( /* webpackChunkName: "login" */ '../views/LoginPage.vue'),
        },
      ]
  },
  { 
    path: '/dashboard',
    component: () => import('@/layout/adminLayout.vue'),
    children: [
      {
        path: 'home',
        name: 'admin',
        component: () => import( /* webpackChunkName: "admin" */ '../views/admin/HomeAdmin.vue'),
      },
      {
        path: 'electronbook',
        name: 'books',
        component: () =>
          import(
            /* webpackChunkName: "booklist" */
            '../views/admin/ElectronBook.vue'
          ),
      },
      {
        path: 'paperbook',
        name: 'paperbook',
        component: () =>
          import(
            /* webpackChunkName: "paperbook" */
            '../views/admin/PaperBook.vue'
          ),
      },
      {
        path: 'givebook',
        name: 'givebook',
        component: () =>
          import(
            /* webpackChunkName: "paperbook" */
            '../views/admin/GiveBook.vue'
          ),
      },
      {
        path: 'addbook',
        name: 'addbook',
        component: () =>
          import(
            /* webpackChunkName: "addbook" */
            '../views/admin/AddBook.vue'
          ),
      },
      {
        path: 'editbook/:id',
        name: 'editbook',
        component: () =>
          import(
            /* webpackChunkName: "editbook" */
            '../views/admin/EditPaperBook.vue'
          ),
      },
      {
        path: 'prof',
        name: 'prof',
        component: () =>
          import(
            /* webpackChunkName: "prof" */
            '../components/admin/ProfileAdmin.vue'
          ),
      },
      {
        path: 'help',
        name: 'help',
        component: () =>
          import( /* webpackChunkName: "help" */ '../views/admin/Help.vue'),
      },
      {
        path: 'student',
        name: 'student',
        component: () =>
          import(
            /* webpackChunkName: "student" */
            '../views/setting/Students.vue'
          ),
      },
      {
        path: 'setting',
        name: 'setting',
        component: () =>
          import( /* webpackChunkName: "setting" */ '../views/SettingPage.vue'),
      },
        {
          path: 'setting/subs',
          name: 'subcategories',
          component: () =>
            import(
              /* webpackChunkName: "subs" */
              '../views/admin/SubcategoryList.vue'
            ),
        },
        {
          path: 'setting/cats',
          name: 'category',
          component: () =>
            import(
              /* webpackChunkName: "cats" */
              '../views/admin/CategoryList.vue'
            ),
          meta: {
            layout: 'admin-layout',
          },
        },
        {
          path: 'setting/language',
          name: 'language',
          component: () =>
            import(
              /* webpackChunkName: "language" */
              '../views/setting/Language.vue'
            ),
          meta: {
            layout: 'admin-layout',
          },
        },
        {
          path: 'setting/requestAdmin',
          name: 'requestAdmin',
          component: () =>
            import(
              /* webpackChunkName: "requestAdmin" */
              '../views/setting/Request.vue'
            ),
          meta: {
            layout: 'admin-layout',
          },
        },
        {
          path: 'setting/filial',
          name: 'filial',
          component: () =>
            import(
              /* webpackChunkName: "        path: 'filial',
      " */
              '../views/setting/FilialList.vue'
            ),
          meta: {
            layout: 'admin-layout',
          },
        },
    ],
  },
  {
    path: '/student',
    component: () => import('@/layout/studentLayout.vue'),
    children: [
      {
        path: '/sub/:id',
        name: 'subcategory',
        component: () =>
          import( /* webpackChunkName: "sub" */ '../views/SubcategoryView.vue'),
      },
    ]
  },
  {
    path: '/search',
    component: () => import('@/layout/searchLayout.vue'),
    children: [
      {
        path: '/bookview',
        name: 'bookview',
        component: () =>
          import( /* webpackChunkName: "bookview" */ '../views/BookViewAll.vue'),
      },
    ]
  },
  {
    path: '/favorite',
    component: () => import('@/layout/favoriteLayout.vue'),
    children: [
      {
        path: '/favorites',
        name: 'favorites',
        component: () =>
          import( /* webpackChunkName: "favorites" */ '../components/Favorites.vue'),
      },
      {
        path: '/book/:id',
        name: 'book',
        component: () =>
          import( /* webpackChunkName: "book" */ '../views/BookView.vue'),
      },
    ]
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
