import axios from 'axios'

export const student = {
    state: () => ({
        specialties:[],
    }),
    getters: {
        specialties({specialties}){
            return specialties
        }
    },
    mutations: {
        set_specialties(state,payload){
            state.specialties = [...payload]
        }
    },
    actions:{
        async get_groups({dispatch},payload){
            return dispatch('request',{
                url: 'api/group',
                params: {
                    ...payload
                }
            })
        },
        async get_students({dispatch},payload){
            return dispatch('request',{
                url: 'api/student',
                params: {
                    ...payload
                }
            })
        },
        new_students({dispatch,commit},payload){
            dispatch('request',{
                url: 'api/student',
                method:'post',
                payload
            })
            .then(res => {
                commit('add_students',res.data.data)
            })
        },
        get_specialties({dispatch,commit},payload){
            dispatch('request',{
                url: 'api/specialty',
                params: {
                    ...payload
                }
            }).then(res => {
                commit('set_specialties',[...res.data.data])
            })
        },
        async getStudentByID({dispatch},payload){            
            return await dispatch('request',{
                url: `givebook/findstudent/${payload}`
            })
        },
        async getBookByInvent({dispatch}, payload) {
            return await dispatch('request',{
                url: `givebook/findinvent/${payload}`
            })
        },
    }
}