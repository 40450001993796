import axios from 'axios';

export const filial = {
  state: () => ({
    filials: [],    
    countfilial: [],
  }),
  getters: {
    filials(state) {
      return state.filials;
    },
    countfilial(state) {
      return state.countfilial;
    },
    activefilial(state) {
      return state.filial.filter((cat) => cat.status === 0);
    },
  },
  mutations: {
    filial(state, payload) {
      state.filials = payload;
    },
    countfilial(state, payload) {
      state.countfilial = payload;
    },
    addfilial(state, payload) {
      state.filials.unshift(payload);
    },
    deletefilial(state, payload) {
      state.filials = state.filials.filter(fil => fil._id !== payload)
    },
    upfilial(state, payload) {
      state.filials = state.filials.map(fil => {
        if (fil._id == payload._id) return payload
        return fil
      })
    },
    statusFilial(state,payload){
      state.filials = state.filials.map((fil) => {
        if (fil._id == payload) return {
          ...fil,
          status: fil.status == 0 ? 1 : 0
        };
        return fil;
      });
    }
  },
  actions: {
    getfilial({dispatch}, payload) {
      return dispatch('request',{
        url: `filial/${payload}`
      });
    },

    deleteFilial({dispatch,commit}, payload) {
      dispatch('request',{
        url: `filial/delete/${payload}`,
        method:'delete'
      })
        .then((res) => {
          if (res.status == 200) {
            commit('notification', {
              type: 'success',
              message: 'Muvafaqqiyatli',
            });
            commit('deletefilial', payload);
          }
        });
    },
    allFilials({dispatch,commit}) {
      dispatch('request',{
        url: 'filial'
      })
        .then((res) => {
            commit('filial', [...res.data.filials])
            commit('countfilial', res.data.count);          
        })
    },
    statusFilial({dispatch,commit},payload){
      dispatch('request',{
        url: `filial/change/${payload}`
      })
        .then((res) => {
          // console.log(res.data);
          commit('statusFilial',payload);
            
        });
    },

    addFilial({dispatch,commit}, data) {
      dispatch('request',{
        url: 'filial/create',
        method:'post',
        data
      })
        .then((res) => {
          if (res.status == 200) {
            commit('notification', {
              type: 'success',
              message: 'Muvafaqqiyatli',
            });
            console.log(res.data);
            commit('addfilial', res.data);

          }
        });
    },
    savefilial({dispatch,commit}, payload) {
      dispatch('request',{
        url: 'filial/save',
        method:'put',
        data:payload
      })
        .then((res) => {
          if (res.status == 200) {
            commit('notification', {
              type: 'success',
              message: 'Muvaffaqiyatli',
            });
            commit('modal', false);
            commit('upfilial', payload);
          }
        });
    },
  },
};
