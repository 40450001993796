import axios from "axios"
import cookie from 'vue-cookies'
import {ElMessage} from "element-plus"

export const subcategory = {
  state: () => ({
    subcategory: [],
    allSubcategory: [],
    onesubcategory: {},
    subcategoryCount:[],
    countSubBook:[]
  }),
  getters: {
    subcategory(state) {
      return state.subcategory
    },
    subcategoryCount(state) {
      return state.subcategoryCount
    },
    countSubBook(state) {
      return state.countSubBook
    },
    getBySubcategory(state) {
      return state.allSubcategory
    },
    activeSubcategory(state) {
      return state.subcategory.filter(cat => cat.status === 0)
    },
    onesubcategory(state) {
      return state.onesubcategory
    },
  },
  mutations: {
    subcategory(state, payload) {
      state.subcategory = payload
    },
    subcategoryCount(state, payload) {
      state.subcategoryCount = payload;
    },
    countSubBook(state, payload) {
      state.countSubBook = payload;
    },
    setSubcategory(state, payload) {
      if (cookie.isKey('favapp')) {
				let favs = JSON.parse(cookie.get('favapp'))
        if(payload.books){
          payload.books = payload.books.map(item => {
            item.fav = favs.includes(item._id);
            return item
          })
        }
			}
      state.allSubcategory = payload
      
    },
    onesubcategory(state, payload) {
      state.onesubcategory = payload
    },
    addSubcategory(state, payload) {
      state.subcategory.push(payload)
    },
    deleteSubcategory(state, payload) {
      let index = state.subcategory.findIndex(cat => cat._id == payload)
      if (index !== -1) {
        state.subcategory.splice(index, 1)
      }
    },
    upSubcategory(state, payload) {
      let index = state.subcategory.findIndex(cat => cat._id == payload._id)
      if (index !== -1) {
        state.subcategory[index] = payload
        state.subcategory[index].status = payload.status ? 0 : 1
      }
    },
    setSubFav(state, payload) {
			let index = state.allSubcategory.books.findIndex(book => book._id == payload.id)
			state.allSubcategory.books[index].fav = payload.fav
		},
  },
  actions: {
    setSubFav(context, payload) {
			context.commit('setSubFav', payload)
		},
    getSubcategory({dispatch}, payload) {
      return dispatch('request',{
        url: `subcategory/${payload}`
      })
    },
    viewSubcategory({dispatch,commit}, payload) {
      dispatch('request',{
        url: `subcategory/edit/${payload}`
      }).then(res => {
        if (res.status === 200) {
          commit('onesubcategory', res.data)
        }
      })
    },
    deleteSubcategory({dispatch,commit}, payload) {
      dispatch('request',{
        url: `subcategory/delete/${payload}`,
        method:'delete'
      })
        .then(res => {
          if (res.status == 200) {
            ElMessage.success('Muvafaqqiyatli o`chirildi')
            commit('deleteSubcategory', payload)
          }
        })
    },
    allSubcategory({commit,dispatch},payload) {
      commit('subcategory', [])
      dispatch('request',{
          url: 'subcategory',
          params: payload
        }).then(res => {

          if (res.status == 200) {
            commit('subcategory', res.data.subcategory)
            commit('subcategoryCount', res.data.count)
          }
        })
    },
    async allsubcategoryCard({commit,dispatch}, payload) {
      commit('loading',true)
      let res = await dispatch('request',{
        url: `category/sub/${payload.category}`,
        params:payload
      })
      if (res.status == 200) {
        commit('loading',false)
        let a = res.data
        if(a.send.books){
            a.send.books = a.send.books.map(book => {
              book.author = book.author ? JSON.parse(book.author) : ''
              return book
            })
        }
        commit('setSubcategory', a.send)
        commit('countSubBook',a.count)
      }
    },
    addSubcategory({commit,dispatch}, data) {
      dispatch('request',{
        url: 'subcategory/create',
        method:'post',
        data
      })
        .then(res => {
          if (res.status == 200) {
            ElMessage.success('Muvafaqqiyatli qo`shildi')
            commit('addSubcategory', res.data)
          }
        })
    },
    saveSubcategory({commit,dispatch}, payload) {
      dispatch('request',{
        url: `subcategory/save/${payload._id}`,
        method: 'put',
        data:payload
      })
        .then(res => {
          if (res.status == 200) {
            ElMessage.success('Muvafaqqiyatli tahrirlandi')
            commit('modal', false)
            commit('upSubcategory', payload)
          }
        })
    }
  }
}