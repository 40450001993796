import axios from 'axios'
import { ElMessage } from 'element-plus'
import cookie from 'vue-cookies'

export const apiStore ={
    actions:{
        async request({getters},payload){
            return await axios({
                url: `${getters.url}/${payload.url}`,
                method: payload.method || "get",
                data: payload.data || null,
                params: payload.params || {},
                timeout: 2000,
                headers: {
                    Authorization: `Bearer ${cookie.get("arm-token")}`,
                },
            })
            
            .catch((e) => {
                console.log(e);
                    ElMessage({
                        type: 'error',
                        message: e.response.data.message,
                    });
                    cookie.remove("arm-token");
                
            });
            
        }
    }

}