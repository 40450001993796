import axios from "axios"

export const dashboard ={
  state: () => ({
    ebooks:[],
    pbooks:[],
    giveBooks:[],
    catbooks:[]
  }),
  getters:{
    ebooks(state) {
			return state.ebooks
		},
    pbooks(state) {
			return state.pbooks
		},
    catbooks(state) {
			return state.catbooks
		},
    giveBooks(state) {
			return state.giveBooks
		},
  },
  mutations:{
    ebooks(state,payload){
      state.ebooks = payload
    },
    pbooks(state,payload){
      state.pbooks = payload
    },
    catbooks(state,payload){
      state.catbooks = payload
    },
    giveBooks(state,payload){
      state.giveBooks = payload
    },
  },
  actions:{
    allEbook({dispatch,commit}){
      dispatch('request',{
        url: 'book/forhome'
      })
      .then(res=> {
          if (res.status==200){
            let books = res.data
            books = books.map(book => {
							book.author = book.author ? JSON.parse(book.author) : ''
							return book
						})
              commit('ebooks',res.data)
          }
      })
    },
    allPbook({dispatch,commit}){
      dispatch('request',{
        url: 'paperbook/forhome'
      })
      .then(res=> {
          if (res.status==200){
            commit('pbooks',res.data)
          }
      })
    },
    allCats({dispatch,commit}){
      dispatch('request',{
        url: 'category/stat'
      })
      .then(res=>{
        if (res.status == 200) {
          commit('catbooks', res.data)
        }
        console.log('res',res.data);
      })
    }
  }
}