import axios from "axios"
import cookie from 'vue-cookies'
import {ElMessage} from "element-plus"

export const book = {
	state: () => ({
		books: [],
		book: {
			title: ''
		},
		bookscount: 0,
		authors: [],
		language: {
			uz: ['O`zbek', 'Rus', 'Ingliz', 'Nemis', 'Koreys', 'Arab', 'Fransuz']
		},
		lang: 'uz',
		others: [],
		resbooks: [],
		allBook: [],
		popularBook: [],
		allview:[],
		searchHome:[]
	}),
	getters: {
		bookscount(state) {
			return state.bookscount
		},
		
		allBook(state) {
			return state.allBook
		},
		popularBook(state) {
			return state.popularBook
		},
		resbooks(state) {
			return state.resbooks
		},
		searchHomeGet(state) {
			return state.searchHome
		},

		others(state) {
			return state.others
		},
		language(state) {
			return state.language[state.lang]
		},
		authors(state) {
			return state.authors
		},
		books(state) {
			return state.books
		},
		book(state) {
			return state.book
		},
		allview(state) {
			return state.allview
		},

		lastbook(state) {
			return state.book.slice(0, 11)
		},

	},
	mutations: {
		bookscount(state, payload) {
			state.bookscount = payload
		},
		
		
		bookAll(state, payload) {
			if (cookie.isKey('favapp')) {
				let favs = JSON.parse(cookie.get('favapp'))
				payload = payload.map(item => {
					item.fav = favs.includes(item._id) || false;
					return item
				})
			} else {
				payload = payload.map(item => {
					item.fav = false;
					return item
				})
			}
			state.allBook = payload
		},
		popularBook(state, payload) {
			if (cookie.isKey('favapp')) {
				let favs = JSON.parse(cookie.get('favapp'))
				payload = payload.map(item => {
					item.fav = favs.includes(item._id);
					return item
				})
			} else {
				payload = payload.map(item => {
					item.fav = false;
					return item
				})
			}
			state.popularBook = payload
		},
		search(state, payload) {
			state.resbooks = payload
		},
		searchHome(state, payload) {
			state.searchHome = payload
		},

		others(state, payload) {
			if (cookie.isKey('favapp')) {
				let favs = JSON.parse(cookie.get('favapp'))
				
				payload = payload.map(item => {
					if(item){
						item.fav = favs.includes(item._id) || false;
					return item
					}
					
				})
			} else {
				payload = payload.map(item => {
					item.fav = false;
					return item
				})
			}
			state.others = payload
		},
		authors(state, payload) {
			state.authors = payload
		},
		book(state, payload) {
			state.book = payload
		},
		allview(state, payload) {
			state.allview = payload
		},
		books(state, payload) {
			if (cookie.isKey('favapp')) { 
				let favs = JSON.parse(cookie.get('favapp'))
				payload = payload.map(item => {
					item.fav = favs.includes(item._id);
					return item
				})
			} else {
				payload = payload.map(item => {
					item.fav = false;
					return item
				})
			}
			state.books = payload
		},
		addBook(state, payload) {
			state.books.push(payload)
		},

		deleteBook(state, payload) {
			let index = state.allBook.findIndex(cat => cat._id == payload)
			if (index !== -1) {
				state.allBook.splice(index, 1)
			}
		},
		upBook(state, payload) {
			let index = state.allBook.findIndex(cat => cat._id == payload._id)
			if (index !== -1) {
				state.allBook[index] = payload
				state.allBook = state.allBook.map(book => {
					if (book._id == payload._id) {
						let year = new Date(payload.year)
						payload.year = year.getFullYear() + ' yil'
						payload.author = JSON.parse(payload.author)
						return payload
					}
					return book
				})
			}
			
		},
		setFav(state, payload) {
			let index = state.books.findIndex(book => book._id == payload.id)
			if (index !== -1) {
				state.books[index].fav = payload.fav
			}
			
		},
		setToFav(state, payload) {
			let index = state.others.findIndex(book => book._id == payload.id)
			state.others[index].fav = payload.fav
		},
		setAllFav(state, payload) {
			let index = state.allBook.findIndex(book => book._id == payload.id)
			state.allBook[index].fav = payload.fav
		},

	},
	actions: {
		setFav(context, payload) {
			context.commit('setFav', payload)
		},
		setToFav(context, payload) {
			context.commit('setToFav', payload)
		},
		setAllFav(context, payload) {
			context.commit('setAllFav', payload)
		},
		
		
		searchList({dispatch,commit}, payload) {
			dispatch('request',{
				url: `book/search/${payload.title}`
			})
				.then(res => {
					if (res.data !== 'error') {
						let datas = res.data.books
						if (datas.length>0) {
							datas = datas.map(book => {
									if (book.author) {
										book.author = JSON.parse(book.author).toString()
									}
									return book
							})
						}
						commit('search', res.data)
					} else {
						commit('search', [])
					}
				})
		},
		searchHome({dispatch,commit}, payload) {
			dispatch('request',{
				url: `book/searchAll/${payload}`
			})
				.then(res => {
					if (res.data !== 'error') {
						commit('searchHome', res.data)
						
					} else {
						commit('searchHome', [])
					}
				})
		},
		async showBook({commit,dispatch}, payload) {
			if(!payload) return false
			commit('book',{})	
			
			let res = await dispatch('request',{
				url: `book/show/${payload}`
			})
			let book = res.data
			let year = new Date(book.year)
			if(book.author){
				book.author = JSON.parse(book.author)
				book.author = book.author.toString()
			}
			book.year = year.getFullYear()
			if (book.language == 0) {
				book.language = "O'zbek tili"
			} else if (book.language == 1) {
				book.language = "Rus tili"
			} else {
				book.language = "Ingliz tili"
			}
			if (book.others?.length>0){
				book.others = book.others.map(item => {
					if(item.author){
						item.author = JSON.parse(item.author)
					}
					return item
				})
				commit('others',book.others)
			}
			book.cat = book.category?.title ? book.category?.title : ''
			commit('book', book)						
		},
		getBooks({dispatch,commit}, payload) {
			if (payload) {
				dispatch('request',{
					url: `book/bytype/${payload}`
				}).then(res => {
					if (res.status === 200) {
						commit('books', res.data)
					}
				})
			}
		},
		bookByCategory({dispatch,commit}, payload) {
			dispatch('request',{
				url: `book/bycategory/all/${payload}`
			}).then(res => {
				if (res.status === 200) {
					let books = res.data
					if (books) {
						books = books.map(book => {
							let year = new Date(book.year)
							if (book.author) {
								book.author = JSON.parse(book.author).toString()
							}
							book.year = year.getFullYear()
							return book
						})
					}
					commit('books', books)
				}
			})
		},
		getBook({dispatch}, payload) {
			return dispatch('request',{
				url: `book/${payload}`
			})
		},
		deleteBook({dispatch,commit}, payload) {
			dispatch('request',{
				url: `book/delete/${payload}`,
				method:'delete',
				
			}).then(res => {
				if (res.status == 200) {
					ElMessage.success('Muvafaqqiyatli o`chirildi')
					commit('deleteBook', payload)
				}
			})
		},
		allBookList({dispatch,commit}) {
			commit('loading', true)
			dispatch('request',{
				url: 'book/all'
			}).then(res => {
					commit('loading', false)
					if (res.status == 200) {
						let {books,count} = res.data
						books = books.map(book => {
							book.author = book.author ? JSON.parse(book.author) : ''
							let year = new Date(book.year)
							book.year = year.getFullYear() + ' yil'
							return book
						})
						commit('bookscount', count)
						commit('books', books)
					}
				})
		},
		allElectronBook({dispatch,commit}, payload) {
			commit('bookAll', [])
			commit('loading', true)
			dispatch('request',{
				url: 'book',
				params: {
					...payload
				},
			}).then(res => {
				let {book,count} = res.data
				book = book.map(book => {
					let year = new Date(book.year)
					book.year = year.getFullYear() + ' yil'
					book.author = book.author ? JSON.parse(book.author) : ''
					return book
				})

				commit('bookscount', count)
				commit('bookAll', book)
				commit('loading', false)
					
				})
		},
		popularBooks({dispatch,commit}) {
			dispatch('request',{
				url: 'book/popular'
			}).then(res => {
				if (res.status == 200) {
					let {
						book
					} = res.data
					if (book) {
						book = book.map(book => {
							book.author = book.author ? JSON.parse(book.author) : ''
							return book
						})
					}
					commit('popularBook', book)
				}
			})
		},
		copyBook({dispatch}, payload) {
			dispatch('request',{
				url: 'book/copy',
				payload
			})
				.then(res => {
					if (res.status == 200) {}
				})
		},
		addBook({dispatch}, data) {
			dispatch('request',{
				url: 'book/create',
				method:'post',
				data
			}).then((res) => {
				ElMessage.success('Muvafaqqiyatli qo`shildi')
			})
		},
		saveBook({dispatch,commit}, payload) {
			dispatch('request',{
				url: `book/save/${payload._id}`,
				method:'put',
				data:payload
			})
				.then(res => {
						ElMessage.success('Muvaffaqiyatli o`zgartirildi')
						commit('upBook',res.data)
					
				})
		},
	}
}